import React from "react";
import MediaBlock from "../components/organisms/media-block";
import { getMediaBlockProps } from "../utils";

const block404 = {
  id: "block404",
  title: "404 Hero",
  ctaText: "View Our Deisgn System",
  classNames: null,
  useGutters: null,
  backgroundColor: "eucalyptus",
  ctaColor: "buffyOrange",
  ctaUrl: null,
  ctaContent: {
    id: "home",
    slug: "",
    sys: {
      contentType: {
        sys: {
          id: "page",
        },
      },
    },
  },
  shortText: "Oops, this page doesn't exist.",
  longText: null,
  image: null,
  supportingImage: null,
  video: null,
  imagePosition: null,
  textColor: "light",
  type: "hero",
};

function Page404() {
  return (
    <MediaBlock
      type={block404.type}
      className={block404.classNames}
      useTitle
      {...getMediaBlockProps(block404)}
    />
  );
}

export default Page404;
